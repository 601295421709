<template>
  <nav>
    <div v-if="mobileFilters" class="overlay"></div>
    <section
      class="navigation"
      :class="[toggleSearchQuery ? 'nav-searchOpen' : { 'nav-untransparent': !showNavbar }]"
      @mouseover="mouseOver()"
      @mouseleave="mouseLeave()"
      :style="
        !expandMenu
          ? ''
          : 'background: white !important; color: #0a3b85 !important; padding: 15px !important; width: 100%;'
      "
    >
      <section v-if="!expandMenu" class="inactive">
        <a href="/">
          <Icon
            v-if="henleySite"
            icon="henley-logo-black"
            class="logo henleyBlack"
            :class="{ 'main-logo': showNavbar }"
          />
          <Icon
            v-else
            icon="ICMA-Centre-Black"
            class="logoICMA"
            :class="{ 'main-logo': showNavbar }"
          />
        </a>
        <a href="/">
          <Icon
            v-if="henleySite"
            icon="henley-logo-white"
            class="logo alternativeLogo"
            :class="{ 'hide-alternativeLogo': !showNavbar }"
          />
          <Icon
            v-else
            icon="ICMA-Centre-White"
            class="logoICMA ICMAalternativeLogo"
            :class="{ 'hide-alternativeLogo': !showNavbar }"
          />
        </a>
        <button v-if="!isSearchPage" class="searchButton" @click.prevent="showSearch">
          <Icon
            v-if="scrolledNavbar || (searchExpanded && toggleSearchQuery)"
            icon="search-icon-blue"
            class="searchIcon"
          />
          <Icon v-else icon="search-icon-white" class="searchIcon" />
        </button>
        <span v-if="!isSearchPage" @click="handler(true)">
          <Icon id="expandMenu" icon="burger-menu" class="toggle" />
        </span>
      </section>
      <section v-else class="active">
        <div>
          <div class="search">
            <Icon icon="searchbar-icon" />
            <input
              type="search"
              placeholder="What are you looking for?"
              v-model="inputText"
              name="q"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="true"
              @keyup.enter="handleQuery"
            />
          </div>
          <span @click="handler(false)">
            <Icon id="expandMenu" icon="close-icon" class="close" />
          </span>
        </div>
      </section>
      <mobile-navigation v-if="menuExpanded" :expanded="expandMenu" class="wrapper" />
    </section>
  </nav>
</template>
<style scoped>
input {
  padding-right: 20px !important;
  color: #333;
  font-weight: normal;
  line-height: 15px;
  opacity: 0.5;
}

input:focus {
  outline: none;
}

.navigation {
  position: fixed;
  z-index: 28;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 80px;
  max-height: 80px;
  align-items: center;
  padding: 15px;
  background: transparent;
  color: white;
  font-size: 12px;
  font-weight: 500;
  overflow-x: hidden;
  overflow-y: auto;
}

.nav-untransparent {
  padding: 20px;
  background: white;
  color: #0a3b85;
}

.nav-searchOpen {
  background: #f8f9fb;
  color: #0a3b85;
}

.nav-searchOpen a {
  color: #0a3b85;
}

.logo {
  width: 96px;
}

.henleyBlack {
  top: -7px;
  left: 25px;
}

.logoICMA {
  top: -50px;
  width: 180px;
}

.alternativeLogo {
  position: absolute;
  top: -7.5px;
  left: 25px;
}

.ICMAalternativeLogo {
  top: -50px;
  left: 20px;
}

.hide-alternativeLogo {
  display: none;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  cursor: pointer;
}

.close:hover {
  background: rgba(10, 59, 133, 0.1);
  border-radius: 50%;
}

.toggle {
  position: absolute;
  right: 20px;
  width: 50px;
  cursor: pointer;
}

.searchIcon {
  position: absolute;
  right: 85px;
  width: 50px;
}

.clickSearch {
  cursor: pointer;
}

.searchButton {
  position: absolute;
}

.search svg {
  position: absolute !important;
  top: 12.5px;
  left: 20px;
  width: 14px;
  opacity: initial;
}

.search input {
  top: 15px;
  left: 20px;
  width: calc(100vw - 85px);
  min-width: 241px;
  height: 40px;
  box-sizing: border-box;
  padding: 10px 75px 10px 45px;
  border: 1px solid rgba(10, 59, 133, 0.3);
  background: rgba(10, 59, 133, 0.01);
  border-radius: 100px;
  font-size: 14px;
}

.inactive img,
.active img,
.inactive svg,
.active svg {
  position: fixed;
}

.inactiveLogo {
  top: -7.5px;
  left: 25px;
}

.main-logo {
  display: none;
}

.inactive {
  position: fixed;
  top: 15px;
}

.active {
  position: fixed;
  top: 20px;
}

.buttons {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 10px;
}

.buttons button {
  min-width: 140px;
  height: 40px;
  padding: 10px 25px 10px 45px;
  margin: 0 5px;
  border-radius: 5px;
  font-weight: 500;
}

.wrapper {
  width: 100%;
}

.overlay {
  position: fixed;
  z-index: 29;
  top: 0;
  right: 0;
  left: 0;
  height: 81px;
  background: rgba(0, 0, 0, 0.4);
}
</style>
<script>
import { createNamespacedHelpers, mapState } from "vuex";

import MobileNavigation from "@/components/assemblies/masthead/components/mobile/MobileNavigation";

import Icon from "@/components/atoms/icon/Icon";

const { mapActions: mapMastheadActions } = createNamespacedHelpers("masthead");

export default {
  components: {
    MobileNavigation,
    Icon,
  },
  data() {
    return {
      inputText: "",
      currentUrl: window.location.pathname,
      activeColour: "#fffff",
      showNavbar: false,
      showMenu: false,
      scrolledNavbar: true,
      activeNavbar: false,
      lastScrollPosition: 0,
      search: null,
      expandMenu: null,
    };
  },
  computed: {
    ...mapState("search", ["query", "activeLevelOfStudyFilter", "mobileFilters", "isSearchPage"]),
    ...mapState("masthead", ["toggleSearchQuery", "menuExpanded", "searchExpanded"]),
    ...mapState("ui", ["site"]),
    henleySite() {
      return this.site.handle === "henleyUk";
    },
  },
  methods: {
    ...mapMastheadActions(["toggleSearch", "toggleMenu", "toggleSearchV2"]),
    showSearch() {
      return this.toggleSearch();
    },
    submit() {
      this.toggleSearch();
    },
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.showNavbar = false;
      this.scrolledNavbar = true;
      this.lastScrollPosition = currentScrollPosition;
    },
    mouseOver() {
      this.showNavbar = false;
      this.activeColour = "#0a3b85";
      this.activeNavbar = false;
      this.scrolledNavbar = true;
    },
    mouseLeave() {
      if (this.toggleSearchQuery) {
        this.showNavbar = false;
        this.activeColour = "#0a3b85";
        this.activeNavbar = false;
        this.scrolledNavbar = true;
      } else {
        setTimeout(() => {
          this.showNavbar = false;
          this.activeColour = "#ffffff";
          this.activeNavbar = true;
          this.scrolledNavbar = true;
        }, 700);
      }
    },
    isModalOpen() {
      document.querySelector("body").style.overflow = this.menuExpanded ? "hidden" : "auto";
    },
    handler(expanded) {
      this.toggleMenu();
      this.isModalOpen();
      this.expandMenu = expanded;
    },
    handleQuery() {
      // open the search and close the hamburger menu
      this.toggleSearchV2();
      this.expandMenu = false;

      // a small delay needed to show ui before populating the state otherwise the ui doesn't update
      setTimeout(() => {
        this.$store.commit("search/SET_QUERY", this.inputText);
        this.inputText = "";
      }, 100);
    },
  },
  mounted() {
    this.currentUrl = window.location.pathname;
    this.scrolledNavbar = true;
    this.showNavbar = false;
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    this.currentUrl = window.location.pathname;
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
