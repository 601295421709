/* eslint-disable prettier/prettier */
<template>
  <section v-if="showBanner">
    <section v-if="endDateHasNotPassed || endDateNotSet">
      <div
        v-if="hasText"
        :class="
          navigationBanner.navigationBannerVariant === 'dark'
            ? 'bg-brand-blue text-white'
            : 'bg-grey-light-3'
        "
        class="notice-banner relative mt-20 w-full flex flex-row justify-center p-2"
      >
        <img :src="navigationBanner.icon" class="h-6 w-6" :alt="navigationBanner.alt" />

        <div class="px-2">
          <a
            v-if="navigationBanner.navigationBannerCtaUrl"
            :href="navigationBanner.navigationBannerCtaUrl"
            class="cursor-pointer hover:underline"
            >{{ navigationBanner.text }}
          </a>

          <span v-else>{{ navigationBanner.text }}</span>
        </div>

        <span class="absolute inset-y-0 right-5 1xl:right-18 content-center flex">
          <button class="close" aria-label="Close" title="close" @click="closeBanner(false)">
            <img
              v-if="navigationBanner.navigationBannerVariant === 'dark'"
              src="@/assets/icons/masthead/light-close.png"
              class="h-3 w-3"
              alt=""
            />
            <img v-else src="@/assets/icons/masthead/dark-close.png" class="h-3 w-3" alt="" />
          </button>
        </span>
      </div>
    </section>
  </section>
</template>
<style scoped>
.notice-banner {
  z-index: 27;
  margin-bottom: -80px;
}
</style>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapState: mapMastheadState } = createNamespacedHelpers("masthead");

export default {
  data() {
    return {
      showBanner: true,
    };
  },
  computed: {
    ...mapMastheadState(["navigationBanner"]),
    endDateHasNotPassed() {
      const today = new Date();
      const navBannerEndDate = new Date(this.navigationBanner.endDate);
      return navBannerEndDate > today;
    },
    endDateNotSet() {
      return this.navigationBanner.endDate === "" ? true : false;
    },
    hasText() {
      return this.navigationBanner.text.length > 0 ? true : false;
    },
  },
  mounted() {
    if (window.sessionStorage.showBanner === "false") {
      this.showBanner = false;
    }
  },
  methods: {
    closeBanner(boolean) {
      if (typeof window !== "undefined") {
        window.sessionStorage.setItem("showBanner", boolean);
      }
      this.showBanner = boolean;
    },
  },
};
</script>
